@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,400i,500,600,700");

@font-face {
  font-family: "Branding";
  src: url("./assets/fonts/Branding-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Branding";
  src: url("./assets/fonts/Branding-SemiBold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Branding";
  src: url("./assets/fonts/Branding-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Branding";
  src: url("./assets/fonts/Branding-Black.otf");
  font-weight: 900;
}

body {
  margin: 0;
  padding: 0 !important;
  overflow-y: scroll !important;
  font-family: "Ubuntu", sans-serif;
}

a {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

/* Async loading progress loader*/
#nprogress .bar {
  background: #00b952 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #00b952 0 0 5px #00b952 !important;
}

#nprogress .spinner-icon {
  border-top-color: #00b952 !important;
  border-left-color: #00b952 !important;
}

/* Finish async loading progress loader */

.cta {
  /* background: linear-gradient(172.24deg, #ff8000 0%, #ffaf00 100%); */
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  background-color: #ff8000 !important;
  border-radius: 5px;
  color: #ffffff !important;
  font-weight: 600 !important;
}

.background-filter::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background: rgb(26, 26, 26);
}

.background-filter {
  position: relative;
}

.background {
  background-image: url("https://upload.wikimedia.org/wikipedia/en/6/62/Kermit_the_Frog.jpg");
  width: 200px;
  height: 200px;
}
.background span {
  position: absolute;
  z-index: 1;
}

.fc-day-today {
  background: transparent !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #ffffff14 !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: 1px solid #ffffff14 !important;
}

.fc-theme-standard .fc-popover {
  border: 1px solid #ffffff14 !important;
  background: #424242 !important;
}

.fc .fc-button-primary {
  color: #fff;
  background-color: #00b952 !important;
  border-color: #00b952 !important;
}
